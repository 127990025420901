import React, { useState, useEffect } from "react";
import Categorias from "./Categorias";
import Videos from "./Videos";
import Buscador from "./Buscador";
import MasNotas from "../notasRecientes/MasNotas";
import axios from "axios";
import Global from "../../Global";

const Sidebar = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getArticles();
  }, [setArticles]);

  const getArticles = () => {
    axios.get(Global.url + "articles").then((res) => {
      setArticles(res.data.articles);
    });
  };

  return (
    <div className="col sidebar">
      <Buscador />
      <Categorias />
      <Videos />

      <div id="sNotasRecientes">
        {
          /* Traer la primer nota */
          articles.slice(1, 4).map((article, i) => {
            return (
              <MasNotas
                key={i}
                url={article._id}
                category={article.category}
                title={article.title}
                date={article.date}
              />
            );
          })
        }
      </div>
    </div>
  );
};

export default Sidebar;
