const Credentials = {
  YTKey: "AIzaSyBWP40h1Tqlnajvmhsp1d5EDhCgKRvPWXs",
  YTChannelId: "UCWkMsuURPMmYuftWxS6MNpg",
  YTUploadsId: "UUWkMsuURPMmYuftWxS6MNpg",
  YTURL:
    "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=UUWkMsuURPMmYuftWxS6MNpg&key=AIzaSyBWP40h1Tqlnajvmhsp1d5EDhCgKRvPWXs",
  YTJuId: "UCch7ndtnduBYNHiUlHlrGIg",
  YTJuUploadsId: "UUch7ndtnduBYNHiUlHlrGIg",
  YTJuURL:
    "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=UUch7ndtnduBYNHiUlHlrGIg&key=AIzaSyBWP40h1Tqlnajvmhsp1d5EDhCgKRvPWXs",
  YTJuURLStreaming:
    "https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCch7ndtnduBYNHiUlHlrGIg&eventType=live&type=video&key=AIzaSyBWP40h1Tqlnajvmhsp1d5EDhCgKRvPWXs",
};

export default Credentials;
