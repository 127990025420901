import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/es";

const NotaPrincipal = (props) => {
  return (
    <div className="col notaPrincipal">
      <div className="imagenContainer">
        <Link to={"blog/nota/" + props.url}>
          <img src={props.image} alt={props.title} />
        </Link>
      </div>
      <span className="notasCategorias">{props.category}</span>
      <h2 className="notasTitulos">
        <Link to={"blog/nota/" + props.url}>{props.title}</Link>
      </h2>
      <span className="notasFechas">
        <Moment format="LL" className="notasFechas">
          {props.date}
        </Moment>
      </span>
      <p className="notasCuerpo">{props.brief}</p>
      <Link to={"blog/nota/" + props.url} className="leerMas">
        Leer Más
        <svg viewBox="0 0 13.762 8.252">
          <g
            id="Group_11"
            data-name="Group 11"
            transform="translate(-173.219 -964.374)"
          >
            <line
              id="Line_1"
              data-name="Line 1"
              className="cls-1"
              x2="12.562"
              transform="translate(173.719 968.5)"
            />
            <line
              id="Line_2"
              data-name="Line 2"
              className="cls-1"
              x2="4.568"
              y2="3.426"
              transform="translate(181.713 965.074)"
            />
            <line
              id="Line_3"
              data-name="Line 3"
              className="cls-1"
              y1="3.426"
              x2="4.568"
              transform="translate(181.713 968.5)"
            />
          </g>
        </svg>
      </Link>
    </div>
  );
};

export default NotaPrincipal;
