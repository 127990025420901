/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import axios from "axios";
import Reaptcha from "reaptcha";
import swal from "sweetalert";
import Global from "../../Global";

const Contacto = () => {
  const [isVeridied, setIsVerified] = useState(false);
  const [sending, setSending] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [captchaReady, setCaptchaReady] = useState(false);
  const [emailData, setEmailData] = useState({
    nombre: null,
    email: null,
    mensaje: null,
  });

  const onLoad = () => {
    setCaptchaReady(true);
  };

  const onVerify = (recaptchaResponse) => {
    setIsVerified(true);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (isVeridied) {
      setSending(true);
      axios.post(Global.url + "mailing/send-mail", emailData).then((res) => {
        swal(
          "Mensaje enviado",
          "El mensaje ha sido enviado correctamente",
          "success"
        );
        setSending(false);
      });
    } else {
      swal("reCaptcha", "Por favor completa el recaptcha", "error");
    }
  };

  return (
    <section id="contacto">
      <div className="container">
        <h2 className="seccionTitulos">
          Contacto
          <span className="linea"></span>
        </h2>
        <div className="col datos">
          <h1>Nosotros</h1>
          <p>
            Somos un medio de alcance nacional con un sistema multiplataforma,
            conformado por redes sociales, facebook, instagram, twitter,
            linkedin, portal de noticias online, radio y canal de video.
          </p>
          <p>
            Informamos y actualizamos sobre el mercado empresarial. Ventas,
            Marketing, Procesos, Managment, RRHH, Finanzas.
          </p>
          <p>
            Tratamos los contenidos de forma tal que se adapten a los diferentes
            canales de comunicación generando asi un mayor impacto y permanencia
            sin perder calidad. Redes sociales, portal, radio y video.
          </p>
        </div>
        <div className="col contactanos">
          <h4>
            Para más información contáctanos a:{" "}
            <a href="mailto:info@generadores.tv">info@generadores.tv</a> o
            envianos un correo a traves del formulario
          </h4>
          <form action="#" id="sendForm" onSubmit={sendEmail}>
            <div className="formGroup">
              <label htmlFor="name">Nombre y apellido</label>
              <input
                type="text"
                name="name"
                id="name"
                minLength={5}
                required
                onChange={(e) => {
                  emailData.nombre = e.target.value;
                }}
                onBlur={(e) => {
                  emailData.nombre = e.target.value;
                }}
              />
            </div>
            <div className="formGroup">
              <label htmlFor="email">Correo electrónico</label>
              <input
                type="email"
                name="email"
                id="email"
                minLength={5}
                required
                onChange={(e) => {
                  emailData.email = e.target.value;
                }}
                onBlur={(e) => {
                  emailData.email = e.target.value;
                }}
              />
            </div>
            <div className="formGroup">
              <label htmlFor="message">Mensaje</label>
              <textarea
                name="message"
                id="message"
                rows="3"
                minLength={5}
                required
                onChange={(e) => {
                  emailData.mensaje = e.target.value;
                }}
                onBlur={(e) => {
                  emailData.mensaje = e.target.value;
                }}
              ></textarea>
            </div>
            <div className="formGroup">
              <Reaptcha
                ref={(e) => setCaptcha(e)}
                sitekey="6LfrWv0UAAAAACw-PRMmCl9gb6QCDG7tEdW2MFhv"
                onLoad={onLoad}
                onVerify={onVerify}
              />
            </div>
            <div className="formGroup">
              <button
                type="submit"
                className={sending ? "cta disabled" : "cta"}
              >
                Enviar consulta
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contacto;
