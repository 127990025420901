import React, { useState } from "react";

const Video = (props) => {
  const controls = useState("?controls=1");
  const disablekb = useState("&disablekb=0");
  const end = useState("&end=10");
  const fs = useState("&fs=0");
  const rel = useState("&rel=0");

  return (
    <div className="col videoPrincipal">
      <div className="videoContainer">
        <iframe
          src={
            "https://www.youtube.com/embed/" +
            props.videoId +
            controls +
            disablekb +
            end +
            fs +
            rel
          }
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          title={props.title}
        ></iframe>
      </div>
    </div>
  );
};

export default Video;
