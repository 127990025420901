import React from "react";

const VideoCard = (props) => {
  return (
    <div className="col videoCard">
      <a href={props.url} target="_blank" rel="noopener noreferrer">
        <svg viewBox="0 0 20 20">
          <g
            id="Group_19"
            data-name="Group 19"
            transform="translate(-1221 -49)"
          >
            <rect
              id="Rectangle_45"
              data-name="Rectangle 45"
              className="cls-1"
              width="10"
              height="10"
              rx="3"
              transform="translate(1227 54)"
            />
            <path
              id="Icon_awesome-youtube"
              data-name="Icon awesome-youtube"
              className="cls-2"
              d="M18.674,6.48a2.262,2.262,0,0,0-1.591-1.6A53.449,53.449,0,0,0,10.05,4.5a53.449,53.449,0,0,0-7.032.379,2.262,2.262,0,0,0-1.591,1.6,23.726,23.726,0,0,0-.376,4.361A23.726,23.726,0,0,0,1.426,15.2a2.228,2.228,0,0,0,1.591,1.576,53.449,53.449,0,0,0,7.032.379,53.449,53.449,0,0,0,7.032-.379A2.228,2.228,0,0,0,18.674,15.2a23.726,23.726,0,0,0,.376-4.361,23.726,23.726,0,0,0-.376-4.361ZM8.209,13.517V8.165l4.7,2.676-4.7,2.676Z"
              transform="translate(1220.95 48.5)"
            />
          </g>
        </svg>
        <img src={props.image} alt={props.title} title={props.title} />
        <h2 className="notasTitulos">{props.title}</h2>
      </a>
    </div>
  );
};

export default VideoCard;
