import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/es";

const MasNotas = (props) => {
  return (
    <div className="nota">
      <span className="notasCategorias">{props.category}</span>
      <h2 className="notasTitulos">
        <Link to={"/redirect-nota/" + props.url} onClick={props.onClick}>
          {props.title}
        </Link>
      </h2>
      <span className="notasFechas">
        <Moment format="LL" className="notasFechas">
          {props.date}
        </Moment>
      </span>
    </div>
  );
};

export default MasNotas;
