import React, { useState } from "react";
import axios from "axios";
import Global from "../../Global";
import swal from "sweetalert";

const Newsletter = () => {
  const emailRef = React.createRef();
  const [newsletter, setNewsletter] = useState({});

  const changeState = () => {
    setNewsletter({
      email: emailRef.current.value,
    });
  };

  const enrollNewsletter = (e) => {
    e.preventDefault();
    axios.post(Global.url + "newsletter/save", newsletter).then((res) => {
      if (res.status === 200) {
        swal("¡Genial!", "Te suscribiste correctamente", "success");
      }
    });
    swal("¡Error!", "El correo electronico ya esta en uso", "error");
  };

  return (
    <section id="newsletter">
      <div className="container">
        <div className="suscribirse">
          <h2 className="col notasTitulos">
            Suscribite para recibir las últimas novedades de Generadores TV
          </h2>
          <form
            id="formSuscribirme"
            className="col"
            onSubmit={enrollNewsletter}
          >
            <input
              type="email"
              id="suscribirseEmail"
              placeholder="Ingresa tu correo electrónico"
              ref={emailRef}
              onChange={changeState}
              onBlur={changeState}
              required
            />
            <button type="submit">Suscribirme</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
