import React from "react";
import logoEcomedios from "../../assets/images/logos/logo-ecomedios.png";
import logoDelenio from "../../assets/images/logos/logo-delenio.png";
import logoTribu from "../../assets/images/logos/logo-tribu.png";
import logoSelica from "../../assets/images/logos/logo-selica.png";

const EmpresasAmigas = () => {
  return (
    <section id="otrasEmpresas">
      <div className="container">
        <h2 className="seccionTitulos">
          Empresas amigas
          <span className="linea"></span>
        </h2>

        <div className="ecomedios">
          <div className="col logo">
            <img src={logoEcomedios} alt="Ecomedios" />
          </div>
          <h2 className="col notasTitulos">
            Escuchanos todos los Martes <br /> de 16 a 17hs!
          </h2>
          <a
            href="http://www.ecohd.com.ar/"
            target="_blank"
            rel="noopener noreferrer"
            className="col botonEcomedios"
          >
            Escuchar Ahora
          </a>
        </div>

        <a
          href="https://www.delenio.net/"
          target="_blank"
          rel="noopener noreferrer"
          className="col empresa"
        >
          <img src={logoDelenio} alt="Delenio" />
        </a>
        <a
          href="http://www.latribucreativa.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="col empresa"
        >
          <img src={logoTribu} alt="La Tribu Creativa" />
        </a>
        <a
          href="https://www.seleccionycapacitacion.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="col empresa"
        >
          <img src={logoSelica} alt="Selica" />
        </a>
      </div>
    </section>
  );
};

export default EmpresasAmigas;
