import React from "react";

const LoadingNotas = () => {
  return (
    <div id="loadingNotas">
      <div className="post">
        <div className="col avatar"></div>
        <div className="col lineas">
          <div className="linea"></div>
          <div className="linea"></div>
        </div>
      </div>
      <div className="post">
        <div className="col avatar"></div>
        <div className="col lineas">
          <div className="linea"></div>
          <div className="linea"></div>
        </div>
      </div>
      <div className="post">
        <div className="col avatar"></div>
        <div className="col lineas">
          <div className="linea"></div>
          <div className="linea"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingNotas;
