import React from "react";
import { Link } from "react-router-dom";
import Notas from "../notas/Notas";

const UltimasNotas = () => {
  return (
    <section id="ultimasNotas">
      <div className="container">
        <h2 className="seccionTitulos">
          Blog
          <Link to="/blog">
            Ver todas las notas
            <svg viewBox="0 0 13.762 8.252">
              <g
                id="Group_11"
                data-name="Group 11"
                transform="translate(-173.219 -964.374)"
              >
                <line
                  id="Line_1"
                  data-name="Line 1"
                  className="cls-1"
                  x2="12.562"
                  transform="translate(173.719 968.5)"
                />
                <line
                  id="Line_2"
                  data-name="Line 2"
                  className="cls-1"
                  x2="4.568"
                  y2="3.426"
                  transform="translate(181.713 965.074)"
                />
                <line
                  id="Line_3"
                  data-name="Line 3"
                  className="cls-1"
                  y1="3.426"
                  x2="4.568"
                  transform="translate(181.713 968.5)"
                />
              </g>
            </svg>
          </Link>
          <span className="linea"></span>
        </h2>
        <Notas isHome={true} articlesLimit="8" />
      </div>
    </section>
  );
};

export default UltimasNotas;
