import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Global from "../../Global";

const NotasDestacadas = () => {
  const [articles, setArticles] = useState([]);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    getArticles();
  }, [setArticles, setStatus]);

  const getArticles = () => {
    axios.get(Global.url + "articles").then((res) => {
      setArticles(res.data.articles);
      setStatus(res.status);
    });
  };

  return status === 200 ? (
    <section id="notasDestacadas">
      <div className="container">
        <h2 className="seccionTitulos">
          Notas destacadas
          <span className="linea"></span>
        </h2>
        {articles.map((article) => {
          return article.featured === true ? (
            <div className="col nota" key={article._id}>
              <h2 className="notasTitulos">
                <Link to={"blog/nota/" + article._id}>{article.title}</Link>
              </h2>
              <span className="notasFechas">
                Escrito por{" "}
                <strong className="notaAutor">{article.author}</strong>.
              </span>
              <p className="notasCuerpo notasCuerpoCompleto">{article.brief}</p>
              <Link to={"blog/nota/" + article._id} className="leerMas">
                Ver Nota
                <svg viewBox="0 0 13.762 8.252">
                  <g
                    id="Group_11"
                    data-name="Group 11"
                    transform="translate(-173.219 -964.374)"
                  >
                    <line
                      id="Line_1"
                      data-name="Line 1"
                      className="cls-1"
                      x2="12.562"
                      transform="translate(173.719 968.5)"
                    />
                    <line
                      id="Line_2"
                      data-name="Line 2"
                      className="cls-1"
                      x2="4.568"
                      y2="3.426"
                      transform="translate(181.713 965.074)"
                    />
                    <line
                      id="Line_3"
                      data-name="Line 3"
                      className="cls-1"
                      y1="3.426"
                      x2="4.568"
                      transform="translate(181.713 968.5)"
                    />
                  </g>
                </svg>
              </Link>
            </div>
          ) : (
            "" // si no hay features notes
          );
        })}
      </div>
    </section>
  ) : (
    ""
  ); // si no hay notas borrar la seccion
};

export default NotasDestacadas;
