import React from "react";

import Sidebar from "../sidebar/Sidebar";
import Notas from "../notas/Notas";
import { useParams } from "react-router-dom";

const Search = () => {
  const { search } = useParams();

  return (
    <section id="blog">
      <div className="container">
        <h1 className="seccionTitulos">
          {"Búsqueda: " + search}
          <span className="linea"></span>
        </h1>
        <div id="blogNotas" className="col">
          <Notas busqueda={search} />
        </div>

        <Sidebar />
      </div>
    </section>
  );
};

export default Search;
