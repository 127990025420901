import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/header/Header";
import NotasRecientes from "./components/notasRecientes/NotasRecientes";
import NotasDestacadas from "./components/notasDestacadas/NotasDestacadas";
import Newsletter from "./components/newsletter/Newsletter";
import UltimasNotas from "./components/ultimasNotas/UltimasNotas";
import UltimosVideos from "./components/ultimosVideos/UltimosVideos";
import EmpresasAmigas from "./components/empresasAmigas/EmpresasAmigas";
import Footer from "./components/footer/Footer";
import BlogList from "./components/blogList/BlogList";
import Search from "./components/search/Search";
import Article from "./components/article/Article";
import CreateArticle from "./components/article/CreateArticle";
import ListarNotas from "./components/article/ListarNotas";
import EditArticle from "./components/article/EditArticle";
import Testing from "./components/testing/Testing.js";
import Contacto from "./components/contacto/Contacto";
import Streaming from "./components/streaming/Straming";

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <Streaming />
      <main>
        {/* Cargar paginas */}
        <Switch>
          <Route exact path="(/|/inicio)">
            <NotasRecientes />
            <NotasDestacadas />
            <Newsletter />
            <UltimasNotas />
            <UltimosVideos />
            <EmpresasAmigas />
          </Route>
          <Route exact path="/blog">
            <BlogList title="Blog" />
          </Route>
          <Route exact path="/blog/categoria/marketing">
            <BlogList title="Marketing" />
          </Route>
          <Route exact path="/blog/categoria/ventas">
            <BlogList title="Ventas" />
          </Route>
          <Route exact path="/blog/categoria/rrhh">
            <BlogList title="RRHH" />
          </Route>
          <Route exact path="/blog/categoria/management">
            <BlogList title={"Management"} />
          </Route>
          <Route exact path="/blog/nota/:id">
            <Article />
          </Route>
          <Route exact path="/contacto">
            <Contacto />
          </Route>
          <Route exact path="/blog/busqueda/:search">
            <Search title="Blog" />
          </Route>
          <Route
            exact
            path="/redirect/:search"
            render={(props) => {
              const search = props.match.params.search;
              return <Redirect to={"/blog/busqueda/" + search} />;
            }}
          />
          <Route
            exact
            path="/redirect-nota/:notaId"
            render={(props) => {
              const notaId = props.match.params.notaId;
              return <Redirect to={"/blog/nota/" + notaId} />;
            }}
          />
          <Route exact path="/blog/crear">
            <CreateArticle />
          </Route>
          <Route exact path="/blog/editar/:articleId">
            <EditArticle />
          </Route>
          <Route exact path="/blog/listar">
            <ListarNotas />
          </Route>
          <Route exact path="/testing">
            <Testing />
          </Route>
        </Switch>
      </main>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
