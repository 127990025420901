import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Global from "../../Global";

const Categoria = (props) => {
  return (
    <Link to={props.url} className="sidebarCategoria">
      <span>{props.title}</span>
      <img src={props.image} alt={props.title} />
    </Link>
  );
};

const Categorias = () => {
  const [categories, setCategories] = useState({});
  const [status, setStatus] = useState(null);

  useEffect(() => {
    getCategories();
  }, [setCategories, setStatus]);

  const getCategories = () => {
    axios.get(Global.url + "categories").then((res) => {
      setCategories(res.data.categories);
      setStatus(res.status);
    });
  };

  return (
    <div id="sCategorias">
      <h2 className="notasCategorias">Categorias</h2>
      {categories.length >= 1 ? (
        categories.map((category, i) => {
          return category.name === "Blog" ? (
            <Categoria
              key={i}
              url={"/" + category.name.toLocaleLowerCase()}
              title={category.name}
              image={Global.url + "category-get-image/" + category.image}
            />
          ) : (
            <Categoria
              key={i}
              url={"/blog/categoria/" + category.name.toLocaleLowerCase()}
              title={category.name}
              image={Global.url + "category-get-image/" + category.image}
            />
          );
        })
      ) : categories.length === 0 && status === 200 ? (
        <h1>no hay categorias para mostrar</h1>
      ) : (
        <h1>cargando</h1>
      )}
    </div>
  );
};

export default Categorias;
