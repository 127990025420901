import React, { useState } from "react";
import axios from "axios";
import Global from "../../Global";
import MasNotas from "../notasRecientes/MasNotas";
import LoadingNotas from "../notas/LoadingNotas";

const SearchOverlay = (props) => {
  const [articles, setArticles] = useState({});
  const [status, setStatus] = useState(null);
  const [inputSearch, setInputSearch] = useState("");
  const handleSearch = (e) => {
    e.preventDefault();
    axios.get(Global.url + "search/" + inputSearch).then((res) => {
      if (res.data.articles !== undefined) {
        setArticles(res.data.articles);
        setStatus(res.status);
      } else {
        setArticles([]);
        setStatus(res.status);
      }
    });
  };

  return (
    <div id="realizarBusqueda">
      <div className="container">
        <div id="realizarBusquedaResultados">
          {articles.length >= 1 && inputSearch.length >= 1 ? (
            articles.map((article) => {
              return (
                <React.Fragment key={article._id}>
                  <MasNotas
                    url={article._id}
                    category={article.category}
                    title={article.title}
                    date={article.date}
                    onClick={props.onClick}
                  />
                  <hr />
                </React.Fragment>
              );
            })
          ) : (articles.length === 0 && status === 200) || status === 204 ? (
            <h1>No hay resultados para la búsqueda</h1>
          ) : (
            <LoadingNotas />
          )}
        </div>
        <form id="realizarBusquedaForm" onSubmit={(e) => e.preventDefault()}>
          <button id="cerrarBuscador" onClick={props.onClick}>
            <svg id="cerrarPopupSVG" viewBox="0 0 47.971 47.971">
              <g>
                <path
                  d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
                      c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
                      C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
                      s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"
                />
              </g>
            </svg>
          </button>
          <h2 className="notasCategorias">Buscar articulos</h2>
          <input
            type="text"
            id="rbf-buscar"
            placeholder="Comenzá a tipear para buscar"
            onChange={(e) => {
              setInputSearch(e.target.value);
              handleSearch(e);
            }}
          />
        </form>
      </div>
    </div>
  );
};

export default SearchOverlay;
