import React, { useState } from "react";
import { Redirect } from "react-router-dom";

const Buscador = () => {
  const searchRef = React.createRef();
  const [search, setSearch] = useState("");
  const [redirect, setRedirect] = useState(false);

  const redirectToSearch = (e) => {
    e.preventDefault();
    setSearch(searchRef.current.value);
    setRedirect(true);
  };

  if (redirect) {
    return <Redirect to={"/redirect/" + search} />;
  }

  return (
    <div id="sBuscador">
      <h2 className="notasCategorias">Buscador:</h2>
      <form onSubmit={redirectToSearch}>
        <input type="text" name="search" ref={searchRef} />
        <button className="cta">Buscar</button>
      </form>
    </div>
  );
};

export default Buscador;
