import React from "react";
import { NavLink } from "react-router-dom";
import NavSocial from "../navSocial/NavSocial";

const Menu = (props) => {
  return (
    <div id="menuMobile" className={props.isOpen ? "open" : ""}>
      <nav>
        <ul>
          <li>
            <NavLink
              exact
              to="/"
              activeClassName="menuActive"
              onClick={props.onClick}
            >
              Inicio
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blog"
              activeClassName="menuActive"
              onClick={props.onClick}
            >
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blog/categoria/marketing"
              activeClassName="menuActive"
              onClick={props.onClick}
            >
              Marketing
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blog/categoria/ventas"
              activeClassName="menuActive"
              onClick={props.onClick}
            >
              Ventas
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blog/categoria/rrhh"
              activeClassName="menuActive"
              onClick={props.onClick}
            >
              RRHH
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blog/categoria/management"
              activeClassName="menuActive"
              onClick={props.onClick}
            >
              Management
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contacto"
              activeClassName="menuActive"
              onClick={props.onClick}
            >
              Contacto
            </NavLink>
          </li>
        </ul>
      </nav>
      <NavSocial />
    </div>
  );
};

export default Menu;
