import React from "react";

import Sidebar from "../sidebar/Sidebar";
import Notas from "../notas/Notas";

const BlogList = (props) => {
  return (
    <section id="blog">
      <div className="container">
        <h1 className="seccionTitulos">
          {props.title}
          <span className="linea"></span>
        </h1>
        <div id="blogNotas" className="col">
          <Notas filterCategory={props.title} />
        </div>

        <Sidebar />
      </div>
    </section>
  );
};

export default BlogList;
