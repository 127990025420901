import React, { useState, useEffect } from "react";
import axios from "axios";
import Global from "../../Global";
import Credentials from "../../Credentials";
import Video from "./Video";
import MasNotas from "./MasNotas";
import NotaPrincipal from "./NotaPrincipal";
import DefaultImage from "../../assets/images/defaultImage.png";
import LoadingNotas from "../notas/LoadingNotas";

const NotasRecientes = () => {
  const [articles, setArticles] = useState([]);
  const [videos, setVideos] = useState({});

  useEffect(() => {
    getArticles();
    getVideos();
  }, [setArticles, setVideos]);

  const getArticles = () => {
    axios.get(Global.url + "articles").then((res) => {
      setArticles(res.data.articles);
    });
  };

  const getVideos = () => {
    axios.get(Credentials.YTJuURL + "&maxResults=1").then((res) => {
      setVideos(res.data.items);
    });
  };

  return (
    <section id="notasRecientes">
      <div className="container">
        {
          //
          videos.length >= 1
            ? videos.map((video) => {
                return (
                  <Video
                    key={video.snippet.resourceId.videoId}
                    videoId={video.snippet.resourceId.videoId}
                    title={video.snippet.title}
                  />
                );
              })
            : articles.slice(3, 4).map((article, i) => {
                return (
                  <NotaPrincipal
                    key={i}
                    url={article._id}
                    category={article.category}
                    title={article.title}
                    date={article.date}
                    image={
                      article.image === null ? DefaultImage : article.image
                    }
                  />
                );
              })
        }
        <div className="col masNotas">
          <h2 className="seccionTitulos">
            Recientes
            <span className="linea"></span>
          </h2>
          {
            /* Traer la primer nota */
            articles.length >= 1 ? (
              articles.slice(0, 3).map((article, i) => {
                return (
                  <MasNotas
                    key={i}
                    url={article._id}
                    category={article.category}
                    title={article.title}
                    date={article.date}
                  />
                );
              })
            ) : (
              <LoadingNotas />
            )
          }
        </div>
      </div>
    </section>
  );
};

export default NotasRecientes;
