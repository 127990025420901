import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import Global from "../../Global";
import axios from "axios";
import NotaCard from "./NotaCard";
import LoadingNotas from "./LoadingNotas";
import NoArticles from "../../assets/images/noArticles.svg";
import DefaultImage from "../../assets/images/defaultImage.png";

const Notas = (props) => {
  const [articles, setArticles] = useState([]);
  const [status, setStatus] = useState(null);
  const [isHome, setIsHome] = useState(false);

  useEffect(() => {
    if (isHome !== props.isHome && props.isHome !== undefined) {
      setIsHome(true);
      getLimitArticles();
    } else if (props.busqueda !== undefined) {
      getArticlesBySearch();
    } else {
      getArticles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setArticles, setStatus]);

  const getArticles = () => {
    axios.get(Global.url + "articles").then((res) => {
      setArticles(res.data.articles);
      setStatus(res.status);
    });
  };
  const getLimitArticles = () => {
    axios.get(Global.url + "articles/" + props.articlesLimit).then((res) => {
      setArticles(res.data.articles);
      setStatus(res.status);
    });
  };
  const getArticlesBySearch = () => {
    axios.get(Global.url + "search/" + props.busqueda).then((res) => {
      if (res.data.articles !== undefined) {
        setArticles(res.data.articles);
        setStatus(res.status);
      } else {
        setArticles([]);
        setStatus(res.status);
      }
    });
  };

  if (articles.length >= 1) {
    // si existen articulos
    return articles.map((article) => {
      if (
        article.category === props.filterCategory ||
        props.filterCategory === "Blog" ||
        props.busqueda !== undefined
      ) {
        return (
          <NotaCard
            key={article._id}
            image={article.image === null ? DefaultImage : article.image}
            url={article._id}
            title={article.title}
            brief={article.brief}
            category={article.category}
            date={article.date}
          />
        );
      } else if (isHome === true) {
        // trae los articulos del home
        return (
          <NotaCard
            key={article._id}
            image={article.image === null ? DefaultImage : article.image}
            url={article._id}
            title={article.title}
            brief={article.brief}
            category={article.category}
          />
        );
      } else {
        return "";
      }
    });
  }
  if (
    (articles.length === 0 && status === 204) ||
    (articles.length === 0 && status === 200)
  ) {
    return (
      <img
        src={NoArticles}
        alt="No se encontraron articulos en su busqueda"
        className="col"
      />
    );
  }
  return <LoadingNotas />;
};

export default Notas;
