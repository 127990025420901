/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";

import NavSocial from "../navSocial/NavSocial";
import isologo from "../../assets/images/isotipo.svg";
import SearchOverlay from "../search/SearchOverlay";

const setHtmlTag = (value) => {
  document.querySelector("html").style.overflow = value;
};

const Footer = () => {
  const [openSearch, setOpenSearch] = useState(false);

  const changeState = () => {
    setOpenSearch(!openSearch);
  };
  return (
    <React.Fragment>
      <footer>
        <div className="container">
          <div className="logo">
            <img src={isologo} alt="Generadores TV" />
          </div>
          <NavSocial onClick={changeState} />
          <div className="copyright">
            <p>2019-2020 - Grupo Generadores</p>
          </div>
        </div>
      </footer>
      {openSearch ? (
        <React.Fragment>
          <SearchOverlay onClick={changeState} />
          <div
            className="overlay open"
            onClick={() => {
              setOpenSearch(!openSearch);
            }}
          ></div>
          {setHtmlTag("hidden")}
        </React.Fragment>
      ) : (
        setHtmlTag("auto")
      )}
    </React.Fragment>
  );
};

export default Footer;
