import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Global from "../../Global";
import swal from "sweetalert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateArticle = () => {
  const [article, setArticle] = useState({
    title: null,
    author: null,
    content: null,
    brief: null,
    category: "Blog",
    status: true,
    featured: false,
  });

  const [categoriesList, setCategoriesList] = useState({});
  const [categoriesStatus, setCategoriesStatus] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [httpStatus, setHttpStatus] = useState(null);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      [{ align: "" }, { align: "center" }, { align: "right" }],

      ["link", "image", "video"],

      ["clean"], // remove formatting button
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [content, setContent] = useState("");
  const [brief, setBrief] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [featured, setFeatured] = useState("");

  // Get categories
  const getCategories = () => {
    axios.get(Global.url + "categories").then((res) => {
      setCategoriesList(res.data.categories);
      setCategoriesStatus(res.status);
    });
  };

  useEffect(() => {
    getCategories();
  }, [categoriesStatus]);

  const fileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const saveArticle = (e) => {
    e.preventDefault();
    if (
      article.title === null ||
      article.author === null ||
      article.content === null ||
      article.brief === null ||
      article.category === null ||
      article.status === null ||
      article.featured === null
    ) {
      swal("Error", "Todos los campos son obligatorios", "error");
    } else {
      setArticle({
        title: title,
        author: author,
        content: content,
        brief: brief,
        category: category,
        status: status,
        featured: featured,
      });
      axios.post(Global.url + "save/", article).then((res) => {
        if (res.data.article) {
          setArticle(res.data.article);
          setHttpStatus("waiting");
          // imagen then upload -- !imagen upload w/o imagen
          if (selectedFile !== null) {
            const articleId = res.data.article._id;
            axios
              .post("https://api.imgur.com/3/image", selectedFile, {
                headers: { Authorization: "Client-ID " + Global.imgurId },
              })
              .then((res) => {
                const imageId = res.data.data.link;
                axios
                  .put(Global.url + "article/" + articleId, {
                    title: title,
                    author: author,
                    content: content,
                    brief: brief,
                    category: category,
                    status: status,
                    featured: featured,
                    image: imageId,
                  })
                  .then((res) => {
                    if (res.data.article) {
                      swal(
                        "Nota creada",
                        "La nota se ha creado correctamente",
                        "success"
                      );
                      setHttpStatus(res.status);
                    } else {
                      setHttpStatus("failed");
                    }
                  });
                //dsad
              });
          } else {
            swal(
              "Nota creada",
              "La nota se ha creado correctamente pero no se ha asignado una imagen de portada",
              "warning"
            );
            setHttpStatus(res.status);
          }
        } else {
          setHttpStatus("failed");
        }
      });
    }
  };

  if (httpStatus === 200) {
    return <Redirect to="/blog" />;
  }

  return (
    <section id="temporalCrearArticulo">
      <div className="container">
        <h2 className="notasTitulos">Crear artículo</h2>
        <form onSubmit={saveArticle}>
          <div className="formGroup">
            <label htmlFor="title">Titulo</label>
            <input
              type="text"
              name="title"
              onChange={(e) => {
                setTitle(e.target.value);
                article.title = e.target.value;
              }}
              onBlur={(e) => {
                setTitle(e.target.value);
                article.title = e.target.value;
              }}
            />
          </div>

          <div className="formGroup">
            <label htmlFor="author">Autor</label>
            <input
              type="text"
              name="author"
              onChange={(e) => {
                setAuthor(e.target.value);
                article.author = e.target.value;
              }}
              onBlur={(e) => {
                setAuthor(e.target.value);
                article.author = e.target.value;
              }}
            />
          </div>

          <div className="formGroup">
            <label htmlFor="content">Contenido</label>
            <ReactQuill
              id="rquill"
              theme="snow"
              value={content}
              onChange={(e) => {
                setContent(e);
                setBrief(document.querySelector("#rquill").innerText);
                article.content = e;
                article.brief = document.querySelector("#rquill").innerText;
              }}
              modules={modules}
            />
          </div>

          <div className="formGroup">
            <label htmlFor="brief">Copete - 200 caracteres máximo</label>
            <textarea
              name="brief"
              rows="3"
              defaultValue={content}
              maxLength={10}
              onChange={() => {
                article.brief = brief;
              }}
            ></textarea>
          </div>

          <div className="formGroup">
            <label htmlFor="category">Selecciona una categoria</label>
            <select
              name="category"
              onChange={(e) => {
                setCategory(e.target.value);
                article.category = e.target.value;
              }}
              onBlur={(e) => {
                setCategory(e.target.value);
                article.category = e.target.value;
              }}
            >
              <option value="Seleccionar">Seleccionar</option>
              {categoriesList.length >= 1 ? (
                categoriesList.map((item) => {
                  return (
                    <option value={item.name} key={item._id}>
                      {item.name}
                    </option>
                  );
                })
              ) : (
                <option value="Blog">Blog</option>
              )}
            </select>
          </div>

          <div className="formGroup">
            <label htmlFor="file0">Imagen destacada</label>
            <input
              type="file"
              name="file0"
              onChange={fileChange}
              onBlur={fileChange}
            />
          </div>

          <div className="formGroup">
            <label htmlFor="status">Estado</label>
            <select
              name="status"
              onChange={(e) => {
                setStatus(e.target.value);
                article.status = e.target.value;
              }}
              onBlur={(e) => {
                setStatus(e.target.value);
                article.status = e.target.value;
              }}
            >
              <option value="true">Publicado</option>
              <option value="false">No publicado</option>
            </select>
          </div>

          <div className="formGroup">
            <label htmlFor="featured">
              Marcar como destacado - se mostrara en las dos notas destacadas
              del inicio
            </label>
            <select
              name="featured"
              onChange={(e) => {
                setFeatured(e.target.value);
                article.featured = e.target.value;
              }}
              onBlur={(e) => {
                setFeatured(e.target.value);
                article.featured = e.target.value;
              }}
            >
              <option value="false">No mostrar</option>
              <option value="true">Mostrar</option>
            </select>
          </div>

          <button type="submit" className="cta">
            Guardar
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateArticle;
