import React, { useState } from "react";
import axios from "axios";
import Global from "../../Global";

const Testing = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const fileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const uploadToImgur = () => {
    axios
      .post("https://api.imgur.com/3/image", selectedFile, {
        headers: { Authorization: "Client-ID " + Global.imgurId },
      })
      .then((res) => {
        console.log(res.data.data.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendForm = (e) => {
    e.preventDefault();
    uploadToImgur();
  };

  return (
    <section>
      <div className="container">
        <h1>aaa</h1>
        <form action="formBonit" onSubmit={sendForm}>
          <input
            type="file"
            name="file0"
            onChange={fileChange}
            onBlur={fileChange}
          />
          <button>enviar</button>
        </form>
      </div>
    </section>
  );
};

export default Testing;
