import React from "react";

const NavSocial = (props) => {
  return (
    <div className="col navSocial">
      <ul>
        <li>
          <a
            href="https://twitter.com/JuanSosaFer/status/1016684890216116224"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg viewBox="0 0 20 20">
              <g
                id="Group_22"
                data-name="Group 22"
                transform="translate(-1101 -49)"
              >
                <rect
                  id="Rectangle_45"
                  data-name="Rectangle 45"
                  className="cls-1"
                  width="20"
                  height="20"
                  rx="3"
                  transform="translate(1101 49)"
                />
                <path
                  id="Icon_awesome-twitter"
                  data-name="Icon awesome-twitter"
                  className="cls-2"
                  d="M16.15,7.024c.011.16.011.32.011.48A10.424,10.424,0,0,1,5.665,18,10.425,10.425,0,0,1,0,16.344a7.631,7.631,0,0,0,.891.046,7.388,7.388,0,0,0,4.58-1.576,3.7,3.7,0,0,1-3.449-2.558,4.652,4.652,0,0,0,.7.057,3.9,3.9,0,0,0,.971-.126A3.69,3.69,0,0,1,.731,8.566V8.52A3.715,3.715,0,0,0,2.4,8.989,3.695,3.695,0,0,1,1.256,4.055a10.486,10.486,0,0,0,7.607,3.86,4.165,4.165,0,0,1-.091-.845,3.693,3.693,0,0,1,6.385-2.524A7.263,7.263,0,0,0,17.5,3.655a3.679,3.679,0,0,1-1.622,2.033A7.4,7.4,0,0,0,18,5.117a7.93,7.93,0,0,1-1.85,1.907Z"
                  transform="translate(1102 48.619)"
                />
              </g>
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/grupogeneradoresok/?modal=admin_todo_tour"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg viewBox="0 0 20 20">
              <g
                id="Group_21"
                data-name="Group 21"
                transform="translate(-1141 -49)"
              >
                <rect
                  id="Rectangle_45"
                  data-name="Rectangle 45"
                  className="cls-1"
                  width="20"
                  height="20"
                  rx="3"
                  transform="translate(1141 49)"
                />
                <path
                  id="Icon_awesome-facebook-f"
                  data-name="Icon awesome-facebook-f"
                  className="cls-2"
                  d="M10.618,10.125l.5-3.258H7.992V4.753a1.629,1.629,0,0,1,1.837-1.76H11.25V.22A17.329,17.329,0,0,0,8.728,0C6.153,0,4.471,1.56,4.471,4.385V6.867H1.609v3.258H4.471V18H7.992V10.125Z"
                  transform="translate(1144.391 50)"
                />
              </g>
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/juansosafernandez/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg viewBox="0 0 20 20">
              <g
                id="Group_20"
                data-name="Group 20"
                transform="translate(-1181 -49)"
              >
                <rect
                  id="Rectangle_45"
                  data-name="Rectangle 45"
                  className="cls-1"
                  width="20"
                  height="20"
                  rx="3"
                  transform="translate(1181 49)"
                />
                <path
                  id="Icon_awesome-linkedin-in"
                  data-name="Icon awesome-linkedin-in"
                  className="cls-2"
                  d="M4.029,18H.3V5.983H4.029ZM2.161,4.344A2.171,2.171,0,1,1,4.322,2.162,2.179,2.179,0,0,1,2.161,4.344ZM18,18H14.272V12.15c0-1.394-.028-3.182-1.94-3.182-1.94,0-2.238,1.515-2.238,3.082V18H6.367V5.983H9.946V7.622H10a3.921,3.921,0,0,1,3.531-1.941C17.306,5.682,18,8.169,18,11.4V18Z"
                  transform="translate(1182 49.999)"
                />
              </g>
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UC4K7C-eZRt7oMzfVl8Yq46Q?view_as=subscriber"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg viewBox="0 0 20 20">
              <g
                id="Group_19"
                data-name="Group 19"
                transform="translate(-1221 -49)"
              >
                <rect
                  id="Rectangle_45"
                  data-name="Rectangle 45"
                  className="cls-1"
                  width="20"
                  height="20"
                  rx="3"
                  transform="translate(1221 49)"
                />
                <path
                  id="Icon_awesome-youtube"
                  data-name="Icon awesome-youtube"
                  className="cls-2"
                  d="M18.674,6.48a2.262,2.262,0,0,0-1.591-1.6A53.449,53.449,0,0,0,10.05,4.5a53.449,53.449,0,0,0-7.032.379,2.262,2.262,0,0,0-1.591,1.6,23.726,23.726,0,0,0-.376,4.361A23.726,23.726,0,0,0,1.426,15.2a2.228,2.228,0,0,0,1.591,1.576,53.449,53.449,0,0,0,7.032.379,53.449,53.449,0,0,0,7.032-.379A2.228,2.228,0,0,0,18.674,15.2a23.726,23.726,0,0,0,.376-4.361,23.726,23.726,0,0,0-.376-4.361ZM8.209,13.517V8.165l4.7,2.676-4.7,2.676Z"
                  transform="translate(1220.95 48.5)"
                />
              </g>
            </svg>
          </a>
        </li>
        <li>
          <button onClick={props.onClick}>
            <svg viewBox="0 0 20 20">
              <g
                id="Group_18"
                data-name="Group 18"
                transform="translate(-1261 -49)"
              >
                <rect
                  id="Rectangle_45"
                  data-name="Rectangle 45"
                  className="cls-1"
                  width="20"
                  height="20"
                  rx="3"
                  transform="translate(1261 49)"
                />
                <path
                  id="Icon_awesome-search"
                  data-name="Icon awesome-search"
                  className="cls-2"
                  d="M17.755,15.565,14.25,12.059a.843.843,0,0,0-.6-.246h-.573a7.31,7.31,0,1,0-1.266,1.266v.573a.843.843,0,0,0,.246.6l3.505,3.505a.84.84,0,0,0,1.192,0l.995-.995a.848.848,0,0,0,0-1.2ZM7.313,11.813a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,7.313,11.813Z"
                  transform="translate(1262 50)"
                />
              </g>
            </svg>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default NavSocial;
