import React, { useEffect, useState } from "react";
import axios from "axios";
import Credentials from "../../Credentials";

const StreamingIframe = (props) => {
  return (
    <iframe
      title={props.title}
      id="YTLive"
      src={"https://www.youtube.com/embed/" + props.videoId}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    ></iframe>
  );
};

const Streaming = () => {
  const [isStreaming, setIsStreaming] = useState(false);
  const [streamingId, setStreamingId] = useState(null);
  const [streamingTitle, setStreamingTitle] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getStreaming = () => {
    // setIsStreaming(true);
    // setStreamingId("3KQ3Ko7gY-4");
    // setStreamingTitle("titulo bonito");
    axios.get(Credentials.YTJuURLStreaming).then((res) => {
      if (res.data.items.length !== 0) {
        setIsStreaming(true);
        setStreamingId(res.data.items[0].id.videoId);
        setStreamingTitle(res.data.items[0].snippet.title);
      }
    });
  };

  useEffect(() => {
    getStreaming();
  }, [setStreamingId, setIsStreaming]);

  return isStreaming ? (
    <div id="estamosEnVivo">
      <StreamingIframe title={streamingTitle} videoId={streamingId} />
      <button
        id="cerrarVivo"
        onClick={() => {
          setIsStreaming(!isStreaming);
        }}
        onBlur={() => {
          setIsStreaming(!isStreaming);
        }}
      >
        <svg id="cerrarPopupSVG" viewBox="0 0 47.971 47.971">
          <g>
            <path
              d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
                  c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
                  C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
                  s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"
            />
          </g>
        </svg>
      </button>
    </div>
  ) : (
    ""
  );
};

export default Streaming;
