import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Global from "../../Global";
import Sidebar from "../sidebar/Sidebar";
import Moment from "react-moment";
import "moment/locale/es";
import NoArticles from "../../assets/images/noArticles.svg";
import LoadingNotas from "../notas/LoadingNotas";
import DefaultImage from "../../assets/images/defaultImage.png";

const Article = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    getArticle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setArticle, setStatus]);

  const getArticle = () => {
    axios.get(Global.url + "article/" + id).then((res) => {
      setArticle(res.data.article);
      setStatus(res.status);
    });
  };

  return article ? (
    <React.Fragment>
      <section id="article-title" className="article">
        <div className="container">
          <div className="articleInfo">
            <img
              id="articleCoverImage"
              src={article.image === null ? DefaultImage : article.image}
              alt={article.title}
            />
            <h1 className="notasTitulos">{article.title}</h1>
            <div className="info">
              <p>
                Por <strong>{article.author}</strong> publicado el <br />
                <Moment format="LL">{article.date}</Moment>
              </p>
            </div>
            <div className="category">
              <p>
                <strong>{article.category}</strong> <br />
                <br />
              </p>
            </div>
          </div>

          <div
            className="articleContent"
            dangerouslySetInnerHTML={{ __html: article.content }}
          ></div>
        </div>
      </section>
      <Sidebar />
    </React.Fragment>
  ) : status === 204 ? (
    <React.Fragment>
      <section className="article">
        <div className="container">
          <img
            src={NoArticles}
            alt="No se encontraron articulos en su busqueda"
            className="col"
          />
        </div>
      </section>
      <Sidebar />
    </React.Fragment>
  ) : (
    <LoadingNotas />
  );
};

export default Article;
