import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Global from "../../Global";
import swal from "sweetalert";
import LoadingNotas from "../notas/LoadingNotas";
import NoArticles from "../../assets/images/noArticles.svg";

const ListarNotas = () => {
  const [articles, setArticles] = useState({});
  const [status, setStatus] = useState(null);

  useEffect(() => {
    getArticles();
  }, [setArticles, setStatus]);

  const getArticles = () => {
    axios.get(Global.url + "articles").then((res) => {
      setArticles(res.data.articles);
      setStatus(res.status);
    });
  };

  const deleteArticle = (id) => {
    swal({
      title: "Estas por borrar esta nota",
      text: "¡Una vez borrada no se puede deshacer!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("La nota se ha borrado correctamente", {
          icon: "success",
        });
        axios.delete(Global.url + "article/" + id).then((res) => {
          setStatus(res.status);
          getArticles();
        });
      } else {
        swal("La nota no se ha borrado");
      }
    });
  };

  return (
    <section id="temporalListarArticulos">
      <div className="container">
        <h1 className="seccionTitulos">
          Listar notas
          <span className="linea"></span>
        </h1>
        {articles.length >= 1 ? (
          articles.map((article) => {
            return (
              <div className="item" key={article._id}>
                <div className="col datos">
                  <h2>{article.title}</h2>
                  <p>{article.brief}</p>
                </div>
                <div className="col acciones">
                  <Link className="y" to={"/blog/editar/" + article._id}>
                    Editar
                  </Link>
                  <button
                    onClick={() => {
                      deleteArticle(article._id);
                    }}
                    className="r"
                  >
                    Borrar
                  </button>
                </div>
              </div>
            );
          })
        ) : articles.length === 0 && status !== null ? (
          <img
            src={NoArticles}
            alt="No se encontraron articulos en su busqueda"
            className="col"
          />
        ) : (
          <LoadingNotas />
        )}
      </div>
    </section>
  );
};

export default ListarNotas;
