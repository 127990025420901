import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import axios from "axios";
import Global from "../../Global";
import swal from "sweetalert";
import LoadingNotas from "../notas/LoadingNotas";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditArticle = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [httpStatus, setHttpStatus] = useState(null);

  const [categoriesList, setCategoriesList] = useState({});
  const [categoriesStatus, setCategoriesStatus] = useState(null);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme

      [{ align: "" }, { align: "center" }, { align: "right" }],

      ["link", "image", "video"],

      ["clean"], // remove formatting button
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [content, setContent] = useState("");
  const [brief, setBrief] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");
  const [featured, setFeatured] = useState("");

  // Get categories
  const getCategories = () => {
    axios.get(Global.url + "categories").then((res) => {
      setCategoriesList(res.data.categories);
      setCategoriesStatus(res.status);
    });
  };

  useEffect(() => {
    getArticle(articleId);
    getCategories();
    // eslint-disable-next-line no-unused-vars
    let aa = article;
    handleFocus();
    if (article.content !== undefined) {
      setContent(article.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setArticle, categoriesStatus]);

  const getArticle = (id) => {
    axios.get(Global.url + "article/" + id).then((res) => {
      setArticle(res.data.article);
    });
  };

  const fileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const saveArticle = (e) => {
    e.preventDefault();
    setArticle({
      title: title,
      author: author,
      content: content,
      brief: brief,
      category: category,
      status: status,
      featured: featured,
    });

    swal({
      title: "Estas por actualizar esta nota",
      text: "¡Una vez actualizada no se puede deshacer!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("La nota se ha actualizado correctamente", {
          icon: "success",
        });
        setArticle({
          title: title,
          author: author,
          content: content,
          brief: brief,
          category: category,
          status: status,
          featured: featured,
        });
        console.log("article");
        console.log(article);
        axios.put(Global.url + "article/" + articleId, article).then((res) => {
          if (res.data.article) {
            setArticle(res.data.article);
            setHttpStatus("waiting");
            // imagen then upload -- !imagen upload w/o imagen
            if (selectedFile !== null) {
              const articleId = res.data.article._id;
              axios
                .post("https://api.imgur.com/3/image", selectedFile, {
                  headers: { Authorization: "Client-ID " + Global.imgurId },
                })
                .then((res) => {
                  const imageId = res.data.data.link;
                  axios
                    .put(Global.url + "article/" + articleId, {
                      title: title,
                      author: author,
                      content: content,
                      brief: brief,
                      category: category,
                      status: status,
                      featured: featured,
                      image: imageId,
                    })
                    .then((res) => {
                      if (res.data.article) {
                        swal(
                          "Nota creada",
                          "La nota se ha creado correctamente",
                          "success"
                        );
                        setHttpStatus(res.status);
                      } else {
                        setHttpStatus("failed");
                      }
                    });
                  //dsad
                });
            } else {
              swal(
                "Nota Actualizada",
                "La nota se ha creado correctamente",
                "success"
              );
              setHttpStatus(res.status);
            }
          } else {
            setHttpStatus("failed");
          }
        });
      } else {
        swal("La nota no se ha actualizado");
      }
    });
  };

  if (httpStatus === 200) {
    return <Redirect to="/blog" />;
  }

  const handleFocus = () => {
    const focusItem = (inputName) => {
      let item = document.querySelector('[name="' + inputName + '"]');
      item.focus();
    };
    // eslint-disable-next-line no-unused-vars
    window.addEventListener("DOMContentLoaded", (event) => {
      if (article.title !== undefined) {
        focusItem("title");
        focusItem("author");
        focusItem("category");
        focusItem("status");
        focusItem("featured");
        focusItem("content");
      }
    });
  };

  return (
    <section id="temporalCrearArticulo">
      <div className="container">
        <h2 className="notasTitulos">Editar artículo</h2>
        {article.title && (
          <form onSubmit={saveArticle}>
            <div className="formGroup">
              <label htmlFor="title">Titulo</label>
              <input
                type="text"
                name="title"
                onChange={(e) => {
                  setTitle(e.target.value);
                  article.title = e.target.value;
                }}
                onBlur={(e) => {
                  setTitle(e.target.value);
                  article.title = e.target.value;
                }}
                defaultValue={article.title}
              />
            </div>

            <div className="formGroup">
              <label htmlFor="author">Autor</label>
              <input
                type="text"
                name="author"
                onChange={(e) => {
                  setAuthor(e.target.value);
                  article.author = e.target.value;
                }}
                onBlur={(e) => {
                  setAuthor(e.target.value);
                  article.author = e.target.value;
                }}
                defaultValue={article.author}
              />
            </div>

            <div className="formGroup">
              <label htmlFor="content">Contenido</label>
              <ReactQuill
                id="rquill"
                theme="snow"
                name="content"
                value={content}
                onChange={(e) => {
                  setContent(e);
                  setBrief(document.querySelector("#rquill").innerText);
                  article.content = e;
                  article.brief = document.querySelector("#rquill").innerText;
                }}
                modules={modules}
              />
            </div>

            <div className="formGroup">
              <label htmlFor="brief">Copete - 200 caracteres máximo</label>
              <textarea
                name="brief"
                rows="3"
                defaultValue={content}
                maxLength={100}
                onChange={() => {
                  article.brief = brief;
                }}
              ></textarea>
            </div>

            <div className="formGroup">
              <label htmlFor="category">Selecciona una categoria</label>
              <select
                name="category"
                onChange={(e) => {
                  setCategory(e.target.value);
                  article.category = e.target.value;
                }}
                onBlur={(e) => {
                  setCategory(e.target.value);
                  article.category = e.target.value;
                }}
                defaultValue={article.category}
              >
                <option value="Seleccionar">Seleccionar</option>
                {categoriesList.length >= 1 ? (
                  categoriesList.map((item) => {
                    return (
                      <option value={item.name} key={item._id}>
                        {item.name}
                      </option>
                    );
                  })
                ) : (
                  <option value="Blog">Blog</option>
                )}
              </select>
            </div>

            <div className="formGroup">
              <label htmlFor="file0">Imagen</label>
              <input
                type="file"
                name="file0"
                onChange={fileChange}
                onBlur={fileChange}
              />
              <div className="traerImagen">
                <img src={article.image} alt={article.title} />
              </div>
            </div>

            <div className="formGroup">
              <label htmlFor="status">Estado</label>
              <select
                name="status"
                onChange={(e) => {
                  setStatus(e.target.value);
                  article.status = e.target.value;
                }}
                onBlur={(e) => {
                  setStatus(e.target.value);
                  article.status = e.target.value;
                }}
                defaultValue={article.status}
              >
                <option value="true">Publicado</option>
                <option value="false">No publicado</option>
              </select>
            </div>

            <div className="formGroup">
              <label htmlFor="featured">
                Marcar como destacado - se mostrara en las dos notas destacadas
                del inicio
              </label>
              <select
                name="featured"
                onChange={(e) => {
                  setFeatured(e.target.value);
                  article.featured = e.target.value;
                }}
                onBlur={(e) => {
                  setFeatured(e.target.value);
                  article.featured = e.target.value;
                }}
                defaultValue={article.featured}
              >
                <option value="false">No mostrar</option>
                <option value="true">Mostrar</option>
              </select>
            </div>

            <button type="submit" className="cta">
              Guardar
            </button>
          </form>
        )}
        {!article.title && <LoadingNotas />}
      </div>
    </section>
  );
};

export default EditArticle;
