/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import NavSocial from "../navSocial/NavSocial";
import Menu from "./Menu";
import SearchOverlay from "../search/SearchOverlay";

const setHtmlTag = (value) => {
  document.querySelector("html").style.overflow = value;
};

const Header = () => {
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const changeState = () => {
    setOpenSearch(!openSearch);
  };
  const changeOpenState = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className="col navLogo">
            <img src={logo} alt="Grupo Generadores" />
          </div>
          <nav className="col" id="menuDesktop">
            <ul>
              <li>
                <NavLink exact to="/" activeClassName="menuActive">
                  Inicio
                </NavLink>
              </li>
              <li>
                <NavLink to="/blog" activeClassName="menuActive">
                  Blog
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blog/categoria/marketing"
                  activeClassName="menuActive"
                >
                  Marketing
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blog/categoria/ventas"
                  activeClassName="menuActive"
                >
                  Ventas
                </NavLink>
              </li>
              <li>
                <NavLink to="/blog/categoria/rrhh" activeClassName="menuActive">
                  RRHH
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blog/categoria/management"
                  activeClassName="menuActive"
                >
                  Management
                </NavLink>
              </li>
              <li>
                <NavLink to="/contacto" activeClassName="menuActive">
                  Contacto
                </NavLink>
              </li>
            </ul>
          </nav>
          <NavSocial onClick={changeState} />
          <div
            id="mmbtn"
            onClick={() => {
              setOpen(!open);
            }}
            className={open ? "open" : ""}
            role="button"
            tabIndex={0}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </header>
      <Menu isOpen={open} onClick={changeOpenState} />
      {open ? (
        <div
          className="overlay open"
          onClick={() => {
            setOpen(!open);
          }}
        ></div>
      ) : (
        ""
      )}
      {openSearch ? (
        <React.Fragment>
          <SearchOverlay onClick={changeState} />
          <div
            className="overlay open"
            onClick={() => {
              setOpenSearch(!openSearch);
            }}
          ></div>
          {setHtmlTag("hidden")}
        </React.Fragment>
      ) : (
        setHtmlTag("auto")
      )}
    </React.Fragment>
  );
};

export default Header;
