import React, { useState, useEffect } from "react";
import axios from "axios";
import Credentials from "../../Credentials";
import VideoCard from "../ultimosVideos/VideCard";

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [status, setStatus] = useState(null);
  const YTCantidad = 1;

  useEffect(() => {
    getVideos();
  }, []);

  const getVideos = () => {
    axios.get(Credentials.YTURL + "&maxResults=" + YTCantidad).then((res) => {
      setVideos(res.data.items);
      setStatus(res.status);
    });
  };

  return (
    <div id="sYoutube">
      <a
        href="https://www.youtube.com/user/cadenaeco/videos"
        target="_blank"
        rel="noopener noreferrer"
        className="notasCategorias"
      >
        <svg viewBox="0 0 20 20">
          <g
            id="Group_19"
            data-name="Group 19"
            transform="translate(-1221 -49)"
          >
            <rect
              id="Rectangle_45"
              data-name="Rectangle 45"
              className="cls-1"
              width="10"
              height="10"
              rx="3"
              transform="translate(1227 54)"
            />
            <path
              id="Icon_awesome-youtube"
              data-name="Icon awesome-youtube"
              className="cls-2"
              d="M18.674,6.48a2.262,2.262,0,0,0-1.591-1.6A53.449,53.449,0,0,0,10.05,4.5a53.449,53.449,0,0,0-7.032.379,2.262,2.262,0,0,0-1.591,1.6,23.726,23.726,0,0,0-.376,4.361A23.726,23.726,0,0,0,1.426,15.2a2.228,2.228,0,0,0,1.591,1.576,53.449,53.449,0,0,0,7.032.379,53.449,53.449,0,0,0,7.032-.379A2.228,2.228,0,0,0,18.674,15.2a23.726,23.726,0,0,0,.376-4.361,23.726,23.726,0,0,0-.376-4.361ZM8.209,13.517V8.165l4.7,2.676-4.7,2.676Z"
              transform="translate(1220.95 48.5)"
            />
          </g>
        </svg>
        Generadores TV
      </a>
      <div id="responseVideos">
        {videos.length >= 1 ? (
          videos.map((video, i) => {
            return (
              <VideoCard
                key={i}
                url={
                  "https://www.youtube.com/watch?v=" +
                  video.snippet.resourceId.videoId
                }
                image={video.snippet.thumbnails.high.url}
                title={video.snippet.title}
              />
            );
          })
        ) : videos.length === 0 && status === 200 ? (
          <h1>No se han encontrado videos</h1>
        ) : (
          <VideoCard
            url="https://www.youtube.com/user/cadenaeco/videos"
            title="Generadores TV"
          />
        )}
      </div>
      <a
        href="https://www.youtube.com/user/cadenaeco/videos"
        target="_blank"
        rel="noopener noreferrer"
        className="cta"
      >
        Mirá mas videos
      </a>
    </div>
  );
};

export default Videos;
