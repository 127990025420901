import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Credentials from '../../Credentials'
import VideoCard from './VideCard'

const UltimosVideos = () => {
  const [videos, setVideos] = useState([])
  const [status, setStatus] = useState(null)
  const YTCantidad = 3

  useEffect(() => {
    getVideos()
  }, [])

  const getVideos = () => {
    axios.get(Credentials.YTURL + '&maxResults=' + YTCantidad).then(res => {
      setVideos(res.data.items)
      setStatus(res.status)
    })
  }

  return (
    <section id='ultimosVideos'>
      <div className='container'>
        <h2 className='seccionTitulos'>
          Ùltimos videos
          <a
            href='https://www.youtube.com/user/cadenaeco/videos'
            target='_blank'
            rel='noopener noreferrer'
          >
            Ir al canal de Youtube
            <svg viewBox='0 0 13.762 8.252'>
              <g
                id='Group_11'
                data-name='Group 11'
                transform='translate(-173.219 -964.374)'
              >
                <line
                  id='Line_1'
                  data-name='Line 1'
                  className='cls-1'
                  x2='12.562'
                  transform='translate(173.719 968.5)'
                />
                <line
                  id='Line_2'
                  data-name='Line 2'
                  className='cls-1'
                  x2='4.568'
                  y2='3.426'
                  transform='translate(181.713 965.074)'
                />
                <line
                  id='Line_3'
                  data-name='Line 3'
                  className='cls-1'
                  y1='3.426'
                  x2='4.568'
                  transform='translate(181.713 968.5)'
                />
              </g>
            </svg>
          </a>
          <span className='linea'></span>
        </h2>
        <div id='responseVideos'>
          {videos.length >= 1 ? (
            videos.map((video, i) => {
              return (
                <VideoCard
                  key={i}
                  url={
                    'https://www.youtube.com/watch?v=' +
                    video.snippet.resourceId.videoId
                  }
                  image={video.snippet.thumbnails.high.url}
                  title={video.snippet.title}
                />
              )
            })
          ) : videos.length === 0 && status === 200 ? (
            <h1>No se han encontrado videos</h1>
          ) : (
            <VideoCard
              url='https://www.youtube.com/user/cadenaeco/videos'
              title='Generadores TV'
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default UltimosVideos
