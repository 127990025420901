import React from 'react'

import Router from './Router'

const App = () => {
  return (
    <React.Fragment>
      <div id='loadingBar'></div>

      <Router />
    </React.Fragment>
  )
}

export default App
